._styles-module__page__1PY8t h1 {
  text-align: center;
  font-size: 19px;
}

._styles-module__code_row__3mDND {
  width: 150px;
}

._styles-module__contact__8RMhi {
  position: relative;
  padding-left: 20px;
}

._styles-module__contact__8RMhi svg {
  color:#b9b9b9;
  position: absolute;
  left: 0px;
  top: 8px;
}

._styles-module__contact__8RMhi p {
  margin-top: 0px;
  margin-bottom: 0px;
}

._styles-module__location__Nopkp {
  position: relative;
  padding-left: 20px;
}

._styles-module__location__Nopkp svg {
  color:#b9b9b9;
  position: absolute;
  left: 0px;
  top: 7px;
}

._styles-module__location__Nopkp p {
  margin-top: 0px;
  margin-bottom: 0px;
}

* a {
  text-decoration: none;
}

* a:hover {
  color: #144988;
  text-decoration: none;
}

p {
  margin-top: 5px;
  margin-bottom: 5px;
}